.signHead {
  font-family: Libre Baskerville;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 51px;
  color: #333333;
}

.signNote {
  font-family: Urbanist;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 110%;
  /* identical to box height, or 18px */
}

.signInput {
  height: 40px;
  width: 100%;
  border-radius: 34px;
  background-color: #fff !important;
  border: 2px #b3b3b357 solid;
}

/* .signForm {
  text-align: -webkit-center;
} */

.orange {
  color: orangered;
}

.loginBtn {
  background-color: #d84727;
  color: #ffffff;
  font-size: small;
  padding: 8px;
  height: 40px;
  width: 150px;
  border-radius: 10px;
  border: none;
}
.note {
  font-family: Urbanist;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 110%;
  /* identical to box height, or 18px */
}
