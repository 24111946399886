.prasad-head {
  font-family: Libre Baskerville;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 152%;
  text-align: center;
  letter-spacing: -0.017em;
  color: #333333;
}

.prasad-para {
  font-family: Urbanist;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 152%;
  /* or 33px */

  letter-spacing: -0.017em;

  color: #333333;
}

.prasadLabel {
  font-family: Libre Baskerville;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 152%;
  /* or 27px */

  letter-spacing: -0.017em;

  color: #333333;
}
#inlineFormCustomSelect {
  height: 40px;
  width: 100%;
  padding-left: 3%;

  border-radius: 34px;
  background-color: #fff !important;
  border: 2px #b3b3b37c solid;
}
.buttonSubmit {
  background-color: #d84727;
  color: #fff;
  border-radius: 34px;
  padding: 2%;
}

.buttonSubmit :hover {
  color: #d84727 !important;
  background-color: #fff !important;
}
#form7 {
  border-radius: 34px;
}

.action_logo {
  height: 70px;
  /* margin-top: 15%; */
  margin-bottom: 15%;
  cursor: pointer;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

.action_logo:hover {
  animation: 1s zoom-in-zoom-out ease-in-out;
}

.paymentIcons {
  text-align: center;
}

.orangeBtn {
  background-color: #d84727;
  color: #ffffff;
  font-size: small;
  padding: 8px;
  height: 50px;
  width: 15rem;
  border-radius: 40px;
  border: none;
  text-align: center;
}

.orangeBtn :hover {
  color: #d84727 !important;
  background-color: #fff !important;
}

@media only screen and (max-width: 991px) {
  .paymentIcons {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
  }
}
