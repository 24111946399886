.white {
  color: #fff;
}
.dropdown-toggle::after {
  color: #d84727;
}

.headerTitle {
  /* font-family: Urbanist; */
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #333333 !important;
  padding: 0.5rem 1rem;
}

.carousel-indicators {
  bottom: -56px;
}

.carousel-indicators button {
  border-radius: 100%;
}

.carousel-indicators [data-bs-target] {
  height: 10px !important;
  width: 10px !important;
  background-color: #555 !important;
  opacity: 0.7;
}

.carousel-indicators .active {
  background-color: #d84727 !important;
}

.home {
  margin-top: 80px;
}

.hometxt {
  /* font-family: Libre Baskerville; */
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  color: #333333;
}

.home-title {
  font-size: 35px;
}

.home-para {
  font-size: 17px;
}
.home-card {
  border: none;
  background-color: #380202;
  height: auto;
  width: 34rem;
  margin: 2%;
  border-radius: 10px;
  /* cursor: pointer;
  transition: transform 1s; */
}
/* 
.home-card:hover {
  transform: scale(1.1);
} */

.homecard-img {
  border-radius: 10px 10px 0 0;
}

.homecard-body {
  padding: 5rem 4rem 2rem 2rem;
}

.homecard-title {
  /* font-family: Libre Baskerville; */
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal !important;
  font-size: 1.5rem;
  line-height: 26px;
  color: #e5e5e5;
}

.homecard-title:after {
  display: block;
  width: 33%;
  border-bottom: 2px solid #fff;
  content: "";
  opacity: 0.5;
  margin: 10px 0;
}

.homecard-text {
  /* font-family: Urbanist; */
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  color: #e5e5e5;
  letter-spacing: 0.8px;
}

.vignette-inset {
  overflow: hidden;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.vignette-inset:after {
  content: "";
  position: absolute;
  /* top: 0; */
  left: 0;
  bottom: 0;
  right: 0;
  box-shadow: inset 0px 25px 10px #1f0f02;
  border-radius: 10px;
}

.lg-footer {
  background-color: #e4826c;
  height: 9vh;
}
.footer-text {
  font-weight: bold;
  font-family: "Inter", sans-serif;
  cursor: pointer;
}
/* .temple_video {
  width: -webkit-fill-available;
  height: 80vh;
  border-radius: 10px;
} */
.home_sub_cont {
  padding-left: 4%;
}
.temple-cont {
  margin-top: 12%;
  margin-bottom: 5%;
  border-radius: 10px;
  /* height: 47vh; */
  /* width: fit-content; */
}
.temple_video {
  width: -webkit-fill-available;
  height: 45vh;
  border-radius: 10px;
}
.visitor_cont {
  padding: 5% 0 5% 0;
}
.visitor {
  /* border: 2px solid #e4826c; */
  box-shadow: inset 0px 0px 2px 1px #e4826c;
  width: min-content;
  padding: 2% 10% 2% 10%;
  border-radius: 10px;
  color: #a51700;
  letter-spacing: 7px;
}
.visit_p {
  color: #4a1600;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
}
.carousel-indicators .li {
  background-color: #f00;
}
.carousel-caption {
  bottom: 0 !important
  ;
}
.caption {
  top: 60% !important;
  bottom: 0;
}
.car_img {
  border-radius: 10px;
  height: auto;
}
.car_caption {
  background-color: #23161a;
  font-size: 2vh;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

/* --------------------------------------------------DIYA----------------------------------------------------------- */

/* .bg_img {
  min-height: -webkit-fill-available;
  width: 100%;
  background-repeat: repeat;
  background-size: cover;
  position: absolute;
} */

.bg-tint {
  background-color: rgba(0, 0, 0, 0.5); /* Tint color */
  background-blend-mode: multiply;
}

.run-animation {
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
  animation: fadeIn 3s linear;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    /* opacity: 0; */
    background-color: rgba(0, 0, 0, 0.5);
  }
  to {
    /* opacity: 1; */
    background-color: rgba(0, 0, 0, 0);
  }
}

@-webkit-keyframes fadeIn {
  from {
    /* opacity: 0; */
    background-color: rgba(0, 0, 0, 0.5);
  }
  to {
    /* opacity: 1; */
    background-color: rgba(0, 0, 0, 0);
  }
}

@keyframes diya-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes diya-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes diya-fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes diya-fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.flame_animation {
  animation: diya-fadeIn 4s linear;
}
.bottom_flame_animation {
  animation: diya-fadeIn 4s linear;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.dia {
  height: 200px;
  min-width: 260px;
  position: absolute;
  right: -116px;
  top: 120px;
  animation: diya-fadeIn 2s linear;
}

.dia_dk {
  height: 200px;
  min-width: 260px;
  position: absolute;
  right: -116px;
  top: 120px;
  /* animation: diya-fadeOut 3s linear; */
}
.show {
  display: block;
}
.hide {
  display: none;
}

.diya-head {
  text-transform: capitalize;
  text-shadow: 0px 3.14974px 6.29948px rgba(0, 0, 0, 0.25);
  font-size: 24px;
  font-family: "Playfair Display";
}
#diya_input {
  height: 40px;
  width: 100%;
  padding-left: 3%;
  color: #e5e5e5;
  border-radius: 34px;
  background-color: rgba(80, 23, 23, 0.568) !important;
  border: 2px rgba(216, 71, 39, 1) solid;
  text-align: center;
}
.add_diya {
  background-color: #d84727;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  border: none;
  padding: 15px; /*7px*/
  border-radius: 5px;
}
a {
  text-decoration: none !important;
}
.disabled {
  background-color: #5d5b5b6e;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  border: none;
  padding: 15px; /*7px*/
  border-radius: 5px;
}
.thankyou {
  text-transform: capitalize;
  text-shadow: 0px 3.14974px 6.29948px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  color: rgb(245, 212, 179) !important;
  font-weight: 700;
  font-family: "Playfair Display";
}
.thankyou_note {
  padding-top: 0px;
}
.candle {
  width: 34px;
  margin: 30px auto 0 auto;
  position: relative;
  height: 60%;
  align-self: flex-end;
  animation: blink 0.1s infinite;
}
.wick {
  position: absolute;
  width: 6px;
  height: 50px;
  background: #23161a;
  top: 150px - 0.8 * 50px;
  left: 50%;
  transform: translateX(-50%) skewX(2deg);
  border-radius: 10%;
  box-shadow: 0 0 2px 0px black;
}
.wick :before {
  content: "";
  position: absolute;
  width: 0;
  left: 50%;
  height: 10px;
  box-shadow: 0 -14px 10px 8px white, 0 -10px 10px 8px rgba(255, 215, 0, 0.7),
    0 -3px 10px 8px rgba(255, 106, 0, 0.7), 0 6px 3px 4px black;
}
.wick :after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0px;
  height: 0px;
  box-shadow: 0 5px 2px 3px gold, 0 20px 2px 14px gold,
    0 -6px 4px 5px rgba(98, 33, 27, 0.8), 0 0px 1px 4px rgba(255, 106, 0, 0.7),
    0 0px 3px 4px #ff9224, 0 5px 3px 4px gold;
}

.flame {
  width: 20px;
  z-index: 1;
  height: 150px;
  margin: 0px auto;
  position: relative;
  animation: move 3s infinite, move-left 3s infinite;
  transform-origin: 50% 90%;
}

.flamedk {
  width: 20px;
  height: 150px;
  margin: 0px auto;
  position: relative;
  animation: move 3s infinite, move-left 3s infinite;
  transform-origin: 50% 90%;
}
.top {
  width: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  border-top-left-radius: 500%;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 500%;
  border-bottom-right-radius: 50px;
  transform: skewY(-10deg);
  box-shadow: 0 0px 0px 3px white, 0 -20px 1px 4px white, 0 -25px 2px 3px gold,
    0 -30px 5px 4px #ff9224, 0 0px 150px 10px #ff9224, 0 -10px 2px 4px white,
    0 -5px 3px 3px white;
  /* animation: flame-up 4s infinite; */
}
.shadows {
  position: absolute;
  left: 50%;
  top: 0;
  width: 1px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 5px 20px 15px gold, 0 0px 100px 20px #ff9224,
    0 15px 50px 15px #ff9224, 5px 30px 5px 13px #ff9224,
    5px 50px 5px 13px #ff9224, 0 75px 50px 30px black;
}
.bottom {
  transform: scale(0.9);
  position: absolute;
  bottom: 6px;
  left: 9px;
  width: 1px;
  height: 8px;
  border-radius: 1%;
  background: #2c2b39;
  box-shadow: 0 6px 10px 12px rgba(60, 76, 125, 0.3), 0 0px 4px 8px #2c2b39,
    0 -12px 10px 8px rgba(255, 106, 0, 0.5), 0 5px 7px 12px #2c2b39,
    0 -3px 10px 12px #2c2b39, 5px -10px 10px 5px red, 0 -15px 10px 10px gold,
    5px -25px 10px 5px gold, 0 2px 5px 10px #30537d, 0 -2px 2px 14px #76daff,
    0 2px 10px 12px #76daff;
}
.lit_bottom {
  transform: scale(0.9);
  position: absolute;
  bottom: 6px;
  left: 9px;
  width: 1px;
  height: 8px;
  border-radius: 1%;
  background: #2c2b39;
  box-shadow: 0 6px 10px 12px rgba(60, 76, 125, 0.3), 0 0px 4px 8px #2c2b39,
    0 -12px 10px 8px rgba(255, 106, 0, 0.5), 0 5px 7px 12px #2c2b39,
    0 -3px 10px 12px #2c2b39, 5px -10px 10px 5px red, 0 -15px 10px 10px gold,
    5px -25px 10px 5px gold, 0 2px 5px 10px #30537d, 0 -2px 2px 14px #76daff,
    0 2px 10px 12px #76daff;
}

.wax {
  position: relative;
  top: 15px;
  width: 100%;
  height: 100%;
  background: #ff9224;
  background: -moz-linear-gradient(
    top,
    #ff9224 0px,
    #ff9224 20px,
    #58523a 50px
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #ff9224 0px,
    #ff9224 20px,
    #58523a 50px
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #ff9224 0px,
    #ff9224 20px,
    #58523a 50px
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9224', endColorstr='#58523a',GradientType=0 ); /* IE6-9 */

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: inset 0 7px 12px -2px #fbf348,
    inset 0 9px 57px -3px rgba(255, 0, 0, 0.4), inset 0 -5px 8px 2px black,
    0 0 3px 0px #ff9224;
}

.taskbar {
  display: flex;
}
@keyframes move {
  0% {
    transform: skewX(2deg) skewY(5deg);
  }
  50% {
    transform: skewX(-2deg) skewY(-0deg);
  }
  100% {
    transform: skewX(2deg) skewY(5deg);
  }
}
@keyframes move-left {
  50% {
    transform: skewX(3deg);
  }
}
@keyframes flame-up {
  50% {
    box-shadow: 0 0px 0px 3px white, 0 -38px 1px 2px white, 0 -41px 2px 3px gold,
      0 -50px 5px 4px #ff9224, 0 0px 150px 10px #ff9224, 0 -10px 2px 4px white,
      0 -5px 3px 3px white;
  }
}
@keyframes blink {
  50% {
    opacity: 0.95;
  }
}

@media only screen and (max-width: 671px) {
  /* .temple_video {
    width: -webkit-fill-available;
    height: auto;
  } */
  .home_sub_cont {
    padding-left: 4%;
    text-align-last: center;
  }
  .visitor_cont {
    text-align: -webkit-center;
  }
  .carousel-indicators {
    bottom: -26px !important;
  }
  .service-para {
    text-align: -webkit-center;
  }
  .car_caption {
    font-size: 1.5vh !important;
  }
  .car_img {
    border-radius: 10px;
    height: 27vh;
  }
  .slide {
    padding-bottom: 6%;
  }
  .caption {
    top: 56% !important;
    bottom: 0;
  }

  .temple-cont {
    width: 100vw;
  }
  .temple_video {
    width: -webkit-fill-available;
    height: 25vh;
    border-radius: 10px;
  }
  .taskbar {
    display: contents;
  }
  .navbar-toggler {
    display: contents;
  }
  .homecard-body {
    padding: 1rem;
  }
  .home-card {
    border: none;
    background-color: #380202;
    height: auto;
    width: 34rem;
    margin: 2%;
    border-radius: 10px;
    cursor: pointer;
    transition: none;
  }
  .home-card:hover {
    transform: none;
  }
  .candle {
    width: 34px;
    margin: 30px auto 0 auto;
    position: relative;
    height: 40%;
    align-self: flex-end;
    animation: blink 0.1s infinite;
  }
  .dia_dk {
    height: 223px;
    min-width: 260px;
    position: absolute;
    right: -123px;
    top: -25px;
  }
  .thankyou_note {
    padding-top: 50px;
  }
  .bottom {
    transform: scale(0.9);
    position: absolute;
    bottom: 150px;
    left: 5px;
    width: 1px;
    height: 8px;
    border-radius: 1%;
    background: #2c2b39;
    box-shadow: 0 6px 10px 12px rgba(60, 76, 125, 0.3), 0 0px 4px 8px #2c2b39,
      0 -12px 10px 8px rgba(255, 106, 0, 0.5), 0 5px 7px 12px #2c2b39,
      0 -3px 10px 12px #2c2b39, 5px -10px 10px 5px red, 0 -15px 10px 10px gold,
      5px -25px 10px 5px gold, 0 2px 5px 10px #30537d, 0 -2px 2px 14px #76daff,
      0 2px 10px 12px #76daff;
  }
  .lit_bottom {
    transform: scale(0.9);
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 1px;
    height: 8px;
    border-radius: 1%;
    background: #2c2b39;
    box-shadow: 0 6px 10px 12px rgba(60, 76, 125, 0.3), 0 0px 4px 8px #2c2b39,
      0 -12px 10px 8px rgba(255, 106, 0, 0.5), 0 5px 7px 12px #2c2b39,
      0 -3px 10px 12px #2c2b39, 5px -10px 10px 5px red, 0 -15px 10px 10px gold,
      5px -25px 10px 5px gold, 0 2px 5px 10px #30537d, 0 -2px 2px 14px #76daff,
      0 2px 10px 12px #76daff;
  }
}

.videoFrame {
  width: 100%;
  height: 100%;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: #1f0f02;
  border-radius: 50%;
  border: 5px solid #1f0f02;
}
