/* .logo360 {
  width: 80px;
} */

#dropdown-basic {
  background-color: #e5e5e5 !important;
  border-color: #e5e5e5 !important;
}
button:focus:not(:focus-visible) {
  outline: #e5e5e5 !important;
  box-shadow: none !important;
}

.diya1 {
  background-color: #d84727;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  border: none;
  padding: 15px; /*7px*/
  border-radius: 5px;
}
.templeLogo {
  width: 110px;
}

.search-box {
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input-search {
  height: 50px;
  /* width: 50px; */
  border-style: none;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  padding-right: 40px;
  color: #555;
}
.dropdown {
  text-align: start;
}
.input-search::placeholder {
  color: rgb(26, 19, 19);
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
}
.btn-search {
  /* width: 50px;
  height: 50px; */
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 6px;
  top: 6px;
  color: #e5e5e5;
  background-color: transparent;
  pointer-events: painted;
}
.btn-search:focus ~ .input-search {
  /* width: 300px; */
  border-radius: 40px;
  border: 2px #b3b3b37c solid;
  background-color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.input-search:focus {
  /* width: 300px; */
  border-radius: 40px;
  border: 2px #b3b3b37c solid;
  background-color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.navbar-toggler {
  font-size: 0.5rem;
  height: 2rem;
}
/* .navbar {
  background-color: transparent;
} */
