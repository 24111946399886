.white {
  color: #fff !important;
}
.intro {
  padding: 1rem 2rem;
}

.category-head {
  /* font-family: Libre Baskerville; */
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 152%;
  text-align: start;
  letter-spacing: 0.017em;
  opacity: 0.8;
}

.category-para {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  line-height: 170%;
  padding-top: 14px;
  letter-spacing: 00.02em;
  opacity: 0.8;
  line-height: 152%;
}

.category-card {
  border: none !important;
  background-color: #380202 !important;
  /* height: 26rem; */
  width: 24rem;
  margin: 2%;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 1s;
}

.category-card:hover {
  transform: scale(1.1);
}
.category-img {
  border-radius: 10px 10px 0 0;
  height: 240px;
}

.categoryCard-title {
  /* font-family: Libre Baskerville; */
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal !important;
  font-size: 1.3rem;
  line-height: 26px;
  color: #e5e5e5;
}
.categoryCard-title:after {
  display: block;
  width: 53%;
  border-bottom: 2px solid #fff;
  content: "";
  opacity: 0.5;
  margin: 10px 0;
}
.categoryCard-para {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 156.5%;
  letter-spacing: 0.04em;
  color: #ffffff;
  opacity: 0.8;
}
iframe {
  display: block; /* iframes are inline by default */
  background: #000;
  border: none; /* Reset default border */
  height: 100vh;
  width: 100vw;
}
.length-chars {
  width: 29ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.background_img {
  min-height: -webkit-fill-available;
  width: 100%;
  background-repeat: repeat;
  background-size: cover;
  position: absolute;
}

.background-tint {
  background-color: rgba(0, 0, 0, 0.8); /* Tint color */
  background-blend-mode: multiply;
}
.nav {
  background-color: transparent !important;
}
.input-search::placeholder {
  color: rgb(255, 255, 255);
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
}

.categoryIntro {
  width: 70%;
}

.categoryCamera {
  padding-right: 50px;
  /* text-align: -webkit-right; */
  transition: transform 1s;
  /* padding-top: 8%; */
}

.camera {
  color: #ffffff;
  position: absolute;
  top: 120px !important;
  left: 10px;
  /* font-family: Urbanist; */
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}

.cameraView {
  color: #ffffff;
  background-color: #d84727;
  position: absolute;
  top: 120px !important;
  right: 0px;
  width: 50px;
  /* font-family: Urbanist; */
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  padding-right: 2%;
  border-radius: 5px 0 0 5px;
}

.categoryTitle {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 350%;
  line-height: 152%;

  letter-spacing: -0.017em;

  color: #ffffff;
}

.categoryDescription {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 152%;
  letter-spacing: 0.002em;
  color: #ffffff;
}

.categoryVid {
  border-radius: 15px;
  cursor: pointer;
  background-size: cover;
  height: 160px;
  width: 300px;
}
.typecam {
  transition: transform 1s;
}

.typecam :hover {
  transform: scale(1.1);
}

.viewBtn {
  background-color: rgba(0, 0, 0, 0.89);
  border: 0.5px #d84727 solid;
  color: #ffffff;
  font-size: small;
  padding: 5px;
}

.watchBtn {
  background-color: #d84727;
  color: #ffffff;
  font-size: small;
  padding: 8px;
  height: 40px;
  width: 8rem;
  border-radius: 40px;
  border: none;
}

.text {
  /* font-family: Urbanist; */
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 17px;
  color: #ffffff;
}

.full-screenVideo {
  height: 500px;
  width: auto;
}
.jumbo {
  background-color: #380202;
  padding: 3rem;
  height: 350px;
  overflow-y: scroll;
}

.service_logo {
  position: "absolute";
  top: "20rem";
  left: "0";
  display: flex;
}

@media only screen and (min-width: 671px) and (max-width: 991px) {
  .category-para {
    font-size: 13px;
    padding-top: 14px;
  }
  .categoryCamera {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 671px) {
  .service_logo {
    position: "absolute";
    top: "20rem";
    left: "0";
    display: none;
  }
  .categoryTitle {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 10vw;
    line-height: 152%;

    letter-spacing: -0.017em;

    color: #ffffff;
  }
  .categoryDescription {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 152%;
    letter-spacing: 0.02em;
    color: #ffffff;
  }
}

.expandable {
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  line-height: 0;
  padding: 1em 0 1em;
  color: #fff;
}

.expandable:target {
  line-height: 1.5;
  padding-top: 1em;
  padding-bottom: 1em;
  color: black;
}

.dark_head_title {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.temple_name {
  position: "absolute";
  background-color: "#d84727";
  color: "#fff";
  border: "1px solid white";
  bottom: "0";
  right: "0";
  padding: "7px";
}

@media only screen and (max-width: 671px) {
  .dark_head_title {
    font-size: 15px;
    color: #fff;
  }
  .temple_name {
    position: "absolute";
    background-color: "#d84727";
    color: "#fff";
    border: "1px solid white";
    bottom: "0";
    right: "0";
    left: 0 !important;
    padding: 2px !important;
  }
  .logohide {
    display: none;
  }
}

.servicesBtn {
  background: none !important;
  border: 1px solid white;
}

.categoryIframe {
  border-radius: 15px;
  cursor: pointer;
  background-size: contain;
  height: 80vh;
  width: 100vw !important;
  margin-bottom: 3%;
}

#react-tabs-3 {
  display: flex;
  justify-content: center;
}

.carousel-control-next,
.carousel-control-prev {
  top: 15%;
  height: 80%;
}
