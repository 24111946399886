.about_or {
  /* border: #c3c1c1 2px solid; */
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
  -moz-box-shadow: inset 0 0 10px #bab3b3;
  -webkit-box-shadow: inset 0 0 10px #bab3b3;
  box-shadow: inset 0 0 10px #bab3b3;
  margin: 10px;
}

.about {
  /* border: #c3c1c1 2px solid; */
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
  -moz-box-shadow: inset 0 0 10px #bab3b3;
  -webkit-box-shadow: inset 0 0 10px #bab3b3;
  box-shadow: inset 0 0 10px #bab3b3;
  margin: 10px;
  text-align-last: center;
}

.service {
  display: flex;
  justify-content: center;
}

.service-head {
  /* font-family: Libre Baskerville; */
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 152%;
  /* or 53px */

  text-align: center;
  letter-spacing: -0.017em;

  color: #333333;
}

.service-para {
  /* font-family: Urbanist; */
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 2.5vh;
  line-height: 152%;
  /* or 33px */

  letter-spacing: -0.017em;

  color: #333333;
}
.service-li {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 18px;
  line-height: 152%;
  /* or 33px */

  letter-spacing: -0.017em;

  color: #333333;
}
.service-card {
  border: none;
  background-color: transparent;
  margin-top: 22% !important;
  height: autos;
  cursor: pointer;
}

.service-intro {
  margin-top: 80%;
  margin-bottom: 5px;
}

.service-title {
  text-decoration: underline;
  text-underline-position: under;
  /* font-family: Libre Baskerville; */
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.017em;

  color: #ffffff;
}

.service-text {
  /* font-family: Urbanist; */
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 156.5%;
  letter-spacing: -0.017em;
  color: #ffffff;
  padding-top: 20px;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 16px;
  background-color: #d84727;
}
